<template>
  <div class="setting-box">
    <nav-bar :title="$t('修改登入密码')" :isFixed="true" :goBack="goBack" :bgc="'background: #ffffff'" />
    <div class="box-main">
      <van-form @submit="onSubmit()">
        <div class="title">{{ $t('原登入密码') }}</div>
        <van-field v-model="params.oldpassword" :type="isOldPassword ? 'password' : 'text'"
        @click-right-icon="isOldPassword = !isOldPassword" :placeholder="$t('請輸入原登入密碼')">
          <template #right-icon>
            <div>
              <img
                v-if="isOldPassword"
                style="width: 20px"
                src="@/assets/icons/eyes.png"
                alt=""
              />
              <img
                v-else
                style="width: 20px"
                src="@/assets/icons/eye-open.png"
                alt=""
              />
            </div>
          </template>
        </van-field>
        <div class="error" v-if="verifyobj.oldpasswordregx">
          {{ $t("請輸入原登入密碼") }}
        </div>
        <div class="title">{{ $t('设定新密码') }}</div>
        <van-field v-model="params.password" :type="isPassword ? 'password' : 'text'"
        @click-right-icon="isPassword = !isPassword" :placeholder="$t('請输入6-15位含字母和數字密碼')" >
          <template #right-icon>
            <div>
              <img
                v-if="isPassword"
                style="width: 20px"
                src="@/assets/icons/eyes.png"
                alt=""
              />
              <img
                v-else
                style="width: 20px"
                src="@/assets/icons/eye-open.png"
                alt=""
              />
            </div>
          </template>
        </van-field>
        <div class="error" v-if="verifyobj.password">
          {{ $t("請输入6-15位含字母和數字密碼") }}
        </div>
        <van-field v-model="params.repassword" :type="isrePassword ? 'password' : 'text'"
        @click-right-icon="isrePassword = !isrePassword" :placeholder="$t('確認密碼')" >
          <template #right-icon>
            <div>
              <img
                v-if="isrePassword"
                style="width: 20px"
                src="@/assets/icons/eyes.png"
                alt=""
              />
              <img
                v-else
                style="width: 20px"
                src="@/assets/icons/eye-open.png"
                alt=""
              />
            </div>
          </template>
        </van-field>
        <div class="error" v-if="verifyobj.passwordregx">
          {{ $t("兩次密碼輸入不一致") }}
        </div>
        <div style="margin: 40px 0 0;">
          <van-button color="#90D12E" block type="primary" native-type="submit">
            {{ $t('提交') }}
          </van-button>
        </div>
        <div class="forgot">
          <span class="forgot-btn" @click="gotoForgot"> {{ $t('忘記密碼') }}?</span>
        </div>
      </van-form>
    </div>
  </div>
</template> 
<script>
import { updatePassword } from "@/api/user"
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
export default {
  name:"changeLoginPassword",
  mixins: [mixins],
  data(){
    return{
      isOldPassword: true,
      isPassword: true,
      isrePassword: true,
      lang: "English",
      langEnv: 2,
      params: {
        oldpassword: null,
        password: null,
        repassword: null,
      },
      verifyobj: {
        oldpasswordregx: "",
        password: "",
        passwordregx: "",
      },
    }
  },
  created(){
    this.langEnv = window.localStorage.getItem("langEnv") || 2
    this.lang =
      window.localStorage.getItem("langEnv") == 2 ? "English" : "繁體中文"
  },
  methods:{
    async onSubmit(){
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      
      let result = await updatePassword({ oldPassword: this.params.oldpassword, newPassword: this.params.repassword })
      if (result.resultID == 1200){
        this.$router.push(`/login?redirect=/user/information`)
      }else{
        this.$toast(result.message)
      }

    },
    // 验证
    handleExamine () {
      // 密码
      const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      this.verifyList = []
      if (!regex.test(this.params.oldpassword)) {
        this.verifyobj["oldpasswordregx"] = true
        return false
      } else {
        delete this.verifyobj["oldpasswordregx"]
      }

      if (!regex.test(this.params.password)) {
        this.verifyobj["password"] = true
        return false
      }  else {
        delete this.verifyobj["password"]
      }

      if (this.params.repassword != this.params.password) {
        this.verifyobj["passwordregx"] = true
        return false
      } else {
        delete this.verifyobj["passwordregx"]
      }
      const keys = Object.keys(this.verifyobj)

      keys.forEach(item => {
        this.validatoFormFormcheck(item)
      })
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
      if (this.verifyList.length > 0) {
        return false
      } else {
        return true
      }
    },
    validatoFormFormcheck (label) {
      if (!this.params[label]) {
        this.verifyList.push(label)
      } else {
        const index = this.verifyList.findIndex(item => item === label)
        if (index != -1) {
          this.verifyList.splice(index, 1)
        }
      }
    },
    gotoForgot () {
      this.$router.push("/rorgotPassword")
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.setting-box{
  padding-top:44px;
  box-sizing: border-box;
  background:#FFFFFF;
  min-height: 100vh;
  .box-main{
    margin:16px 10px 24px;
    padding:0 16px;
    box-sizing: border-box;
    background:#ffffff;
    border-radius: 12px 12px 12px 12px;
    .title {
      font-weight: 500;
      font-size: 17px;
      color: #1A1A1A;
      line-height: 24px;
    }
    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .van-field {
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 16px;
      padding: 16px 0;

      .van-field__control {
        font-size: 16px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;

        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          color: #BDBDBD;
          line-height: 24px;
        }
      }
    }
    .forgot {
      margin:20px auto 0;
      text-align:center;
      .forgot-btn{
        font-size: 15px;
        line-height: 24px;
        color: #90d12e;
      }
    }
  }
}
</style>